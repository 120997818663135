<template>
  <div id="info_techlo">
    <div class="banner">
      <h2>信息技术服务</h2>
      <h3>物流应用系统矩阵构建</h3>
      <h3>业务与IT流程融合再造</h3>
      <h3>企业数字化基础设施</h3>
    </div>
    <div class="container">
      <div class="floor_1 floor">
        <div class="title">物流应用系统矩阵构建</div>
        <div class="f1_main">
          <div class="left">
            <img src="~assets/image/infoTechlo/f1_1.png" alt="" />
          </div>
          <div class="right">
            <p class="r_top">
              全维度产品线，实现物流全链路应用系统覆盖，全过程数字化管理
            </p>
            <div class="r_bottom">
              <div class="item">
                <span class="point"></span>
                <div class="i_right">
                  <span class="t1">业务管理:</span
                  ><span class="t2">ELP物流门户、OMS订单管理</span>
                </div>
              </div>
              <div class="item">
                <span class="point"></span>
                <div class="i_right">
                  <span class="t1">运营管理:</span
                  ><span class="t2">BMS计费管理、OAS运营分析 </span>
                </div>
              </div>
              <div class="item">
                <span class="point"></span>
                <div class="i_right">
                  <span class="t1">运作管理:</span
                  ><span class="t2">WMS仓储管理 ,TMS运配管理，WCS设备控制</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="floor_2 floor">
        <div class="title">业务与IT流程融合再造</div>
        <div class="f2_main">
          <div class="left">
            <p class="l_top">
              基于数据，数字物流管理模型进行分析诊断，实现流程与IT管控融合
            </p>
            <div class="l_bottom">
              <div class="item">
                <span class="point"></span>
                <div class="i_right">
                  <span class="t1">分析诊断:</span
                  ><span class="t2">系统数据汇集，统计分析与问题诊断 </span>
                </div>
              </div>
              <div class="item">
                <span class="point"></span>
                <div class="i_right">
                  <span class="t1">流程开发:</span
                  ><span class="t2"
                    >物流的运营、作业流程的梳理、改造与调优
                  </span>
                </div>
              </div>
              <div class="item">
                <span class="point"></span>
                <div class="i_right">
                  <span class="t1">运行管控:</span
                  ><span class="t2">BMP流程工具开发流程与实施，执行管控 </span>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <img src="~assets/image/infoTechlo/f1_2.png" alt="" />
          </div>
        </div>
      </div>
      <div class="floor_3 floor">
        <div class="title">企业数字化基础设施</div>
        <div class="f3_main">
          <div class="left">
            <img
              src="~assets/image/infoTechlo/f1_3.png"
              style="width: 5.41rem; height: 4.15rem"
            />
          </div>
          <div class="right">
            <p class="r_top">
              基于敏捷研发工具与集成工具，业务与数据的双中台架构，实现企业多系统的融合应用
            </p>
            <div class="r_bottom">
              <div class="item">
                <span class="point"></span>
                <div class="i_right">
                  <span class="t1">企业信息集成:</span
                  ><span class="t2"
                    >应用MIP集成平台工具，无缝集成各分子系统的数据、服务与应用，快速满足业务。
                  </span>
                </div>
              </div>
              <div class="item">
                <span class="point"></span>
                <div class="i_right">
                  <span class="t1">技术架构治理:</span
                  ><span class="t2"
                    >互联网/物联网/大数据架构升级转型，服务与应用中心化治理，业务与数据双中台建设。
                  </span>
                </div>
              </div>
              <div class="item">
                <span class="point"></span>
                <div class="i_right">
                  <span class="t1">敏捷开发体系:</span
                  ><span class="t2"
                    >TDP技术开发平台与DDP数据开发平台，全WEB研发与管理环境，提升效率70%以上。
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoTechlo",
  data() {
    return {};
  },
};
</script>
<style lang='scss' scoped>
#info_techlo {
  position: relative;
  width: 100%;

  background: #ffffff;
  .banner {
    padding: 0.94rem 0 0 3.2rem;
    height: 3.655rem;
    background: url("../assets/image/infoTechlo/b_1.png") no-repeat;
    background-size: cover;
    h2 {
      font-size: 0.36rem;
      font-weight: bold;
      color: #525252;
    }

    h3 {
      font-size: 0.16rem;
      font-weight: 400;
      color: #666666;
    }
    h3:nth-of-type(n + 2) {
      margin-top: 0.14rem;
    }
    h3:nth-of-type(1) {
      margin-top: 0.32rem;
    }
  }
  .container {
    [class^="floor_"] {
      width: 100%;
      padding-top: 1.22rem;
      [class$="title"] {
        text-align: center;
        font-size: 0.4rem;
        font-weight: bold;
        color: #303133;
      }
      [class$="_main"] {
        display: flex;
        justify-content: center;
        margin-top: 0.59rem;
        height: 4.61rem;
      }
    }
    .floor_1,
    .floor_3 {
      height: 7.4rem;
      padding-top: 1.2rem;
      .f1_main,
      .f3_main {
        .left {
          img {
            width: 6.46rem;
            height: 4.22rem;
          }
        }
        .right {
          margin-top: 0.62rem;
          margin-left: 0.94rem;
          .r_top {
            width: 5.38rem;
            font-size: 0.26rem;
            font-weight: 400;
            color: #303133;
            line-height: 0.4rem;
          }
          .r_bottom {
            margin-top: 0.57rem;
            .item {
              margin-bottom: 0.18rem;
              .point {
                display: inline-block;
                width: 0.04rem;
                height: 0.04rem;
                margin-right: 0.1rem;
                margin-bottom: 0.02rem;
                background: #666666;
                border-radius: 50%;
              }
              .i_right {
                display: inline-block;
                width: 5.08rem;
                font-size: 0.14rem;
                font-weight: 400;
                color: #333333;
                line-height: 0.24rem;
                .t1 {
                  color: #333333;
                }
                .t2 {
                  color: #666666;
                  margin-left: 0.08rem;
                }
              }
            }
          }
        }
      }
    }

    .floor_2 {
      height: 6.77rem;
      background: #f7f7f7;
      .f2_main {
        margin-top: 0.62rem;
        .left {
          margin-right: 1.18rem;
          .l_top {
            width: 5.08rem;
            font-size: 0.26rem;
            font-weight: 400;
            color: #303133;
            line-height: 0.4rem;
            width: 5.08rem;
          }
          .l_bottom {
            margin-top: 0.56rem;
            .item {
              margin-bottom: 0.19rem;
              .point {
                display: inline-block;
                width: 0.04rem;
                height: 0.04rem;
                margin-right: 0.1rem;
                margin-bottom: 0.02rem;
                background: #666666;
                border-radius: 50%;
              }
              .i_right {
                display: inline-block;
                width: 5.08rem;
                font-size: 0.14rem;
                font-weight: 400;
                color: #333333;
                line-height: 0.24rem;
                .t1 {
                  color: #333333;
                }
                .t2 {
                  color: #666666;
                  margin-left: 0.08rem;
                }
              }
            }
          }
        }
        .right {
          img {
            width: 6.21rem;
            height: 3.36rem;
          }
        }
      }
    }
  }
}
</style>